import styled from "styled-components"

export const AboutPageStyle = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: flex-start;
  flex-direction: column;
  img {
    max-width: 430px;
    width: 100%;
    height: auto;
    align-self: center;
  }
  
  @media screen and (min-width: 750px) {
    flex-direction: row;
    img {
      align-self: flex-start;
    }
  }
`

export const AboutContent = styled.div`
    line-height: 1.4rem;
`
export const AboutList = styled.ul`
  list-style-type: circle;
  margin-left: 20px;
  margin-top: 20px;
  li {
    margin-bottom: 40px;
  }
`
export const Paragraphe1 = styled.p`
  margin-bottom: 35px;
`
export const Paragraphe2 = styled.p``
