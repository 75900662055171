import styled from "styled-components"

export const PatnerPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  p {
    margin: 25px 0;
    text-align: center;
    line-height: 22px;
  }
  
  img {
    max-width: 370px;
    width: 100%;
  }
`

export const AlignImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  
  img {
    max-width: 300px;
    width: 100%;
  }
`
