import styled from 'styled-components'

export const FooterComponentStyle = styled.footer`
  width: 100%;
  margin: 20px 0;
`

export const LeftLink = styled.section``

export const RightLink = styled.section`
  a {
    color: #3B5998;
  }
`

export const SectionLink = styled.section`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
`

export const SectionCopyright = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    align-self: center;
    &:first-child {
      font-weight: bold;
      font-family: 'parchment', cursive;
      font-size: 1.5rem;
    }
  }
`

export const TitleLink = styled.h1`
  font-family: 'parchment', cursive;
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.5rem;
  margin-bottom: 10px;
`
