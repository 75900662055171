import styled from "styled-components"

export const HomePageStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FirstImageHome = styled.img`
  max-width: 600px;
  align-self: center;
  width: 100%;
`
export const LastImageHome = styled.img`
  max-width: 400px;
  align-self: center;
`

export const TitleHome = styled.h1`
  font-family: 'parchment', cursive;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: .09rem;
  margin: 20px 0;
`

export const AlignImages = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 30px 0;
  flex-wrap: wrap;
  img {
    width: 100%;
    max-width: 400px;
  }
`

export const AlignImages2 = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
  flex-wrap: wrap;
  
  img {
    max-height: 300px;
    border: solid 2px black;
  }
  @media screen and (max-width: 754px) {
    gap: 20px;
    img {
      &:nth-child(1) {
        max-height: 300px;
      }
      &:first-child, &:last-child{
        width: 100%;
        max-height: 600px;
      }
    }
  }
`


export const HomeLists = styled.ul`
  list-style-type: circle;
  margin: 15px 0 0 50px;
  li {
    margin-bottom: 15px;
  }
`
