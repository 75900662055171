import React, {useState} from 'react'
import {CloseMenuButton, MenuBackDrop, MenuContent, MenuStyle, MobileBurgerMenu} from "./Menu.component.style"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {NavLink} from "react-router-dom"

export const MenuComponent = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <MenuStyle>
            <MobileBurgerMenu onClick={() => setIsOpen(true)}>
                <FontAwesomeIcon size={"lg"} icon={['fa', 'bars']} />
            </MobileBurgerMenu>
            <MenuBackDrop className={isOpen ? 'open': ''} onClick={() => setIsOpen(false)}/>
            <MenuContent className={isOpen ? 'open': ''}>
                <CloseMenuButton onClick={() => setIsOpen(false)}>
                    <FontAwesomeIcon size={"lg"} icon={['fa', 'times']} />
                </CloseMenuButton>
                <NavLink to={'/'}>
                    L'équithérapie
                </NavLink>
                <NavLink to={'/qui-suis-je'}>
                    Qui suis-je?
                </NavLink>
                <NavLink to={'/prise-en-charge'}>
                    Votre prise en charge
                </NavLink>
                <NavLink to={'/partenaire'}>
                    Mes partenaires
                </NavLink>
                <NavLink to={'/contact'}>
                    Accès et contact
                </NavLink>
                <a href={'https://www.facebook.com/emiliegiraudeau.equitherapeute'}>
                    Facebook
                </a>
            </MenuContent>
        </MenuStyle>
    )
}
