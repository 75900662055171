import styled from 'styled-components'

export const MainContent = styled.main`
  padding: 25px 35px;
  border: 2px solid #aab4d8;
  border-radius: 30px;
  background: white;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const MainContainer = styled.div`
  width: 100%;
  padding: 0 15px;
`
