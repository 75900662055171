import React from 'react'
import {
    FooterComponentStyle,
    LeftLink,
    RightLink,
    SectionCopyright,
    SectionLink,
    TitleLink
} from "./Footer.component.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const FooterComponent = () => {
    const date = new Date()
    return (
        <FooterComponentStyle>
            <SectionLink>
                <LeftLink>
                    <TitleLink>Liens utiles :</TitleLink>
                    <a href="https://www.ecuries-fidelis.fr/">Ecuries Fidelis</a>
                </LeftLink>
                <RightLink>
                    <TitleLink>Suivez-nous sur : </TitleLink>
                    <a href="https://www.facebook.com/emiliegiraudeau.equitherapeute"><FontAwesomeIcon size={"lg"} icon={['fab', 'facebook']} /></a>
                </RightLink>
            </SectionLink>
            <SectionCopyright>
                <div>J'en parlerai à mon cheval --- {date.getFullYear()}</div>
                <div>- Tout droit réservé -</div>
            </SectionCopyright>
        </FooterComponentStyle>
    )
}
