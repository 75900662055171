import styled from "styled-components"

export const SupportPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  
  img {
    max-width: 600px;
    width: 100%;
    margin-bottom: 15px;
  }
  
  p {
    line-height: 22px;
    margin-bottom: 15px;
  }
`

export const MailContent = styled.p`
  @media screen and (max-width: 400px){
    font-size: 0.8rem;
  }
`

export const BoldPart = styled.span`
  font-weight: 600;
`
