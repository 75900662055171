import React from 'react'
import MainLayout from "../../components/layout/main.layout"
import {AlignImages, PatnerPageStyle} from "./Partner.page.style"

export const PartnerPage = () => {
    return (
        <MainLayout>
            <PatnerPageStyle>
                <p>Je travaille avec des chevaux et poneys que je connais très bien, avec lesquels je créé une relation
                    en dehors des séances par le biais de diverses activités.</p>

                <AlignImages>
                    <img src={process.env.PUBLIC_URL + '/images/partenaire1.jpg'} alt="Partenaire 1" />
                    <img src={process.env.PUBLIC_URL + '/images/partenaire2.jpg'} alt="Partenaire 2"/>
                    <img src={process.env.PUBLIC_URL + '/images/partenaire3.jpg'} alt="Partenaire 3"/>

                </AlignImages>

                <p>Je considère les chevaux et poneys avec lesquels je travaille comme des partenaires à part entière,
                    qui me facilitent le dialogue avec mes patients. Ils seront toujours respectés en tant que tels.</p>

                <img src={process.env.PUBLIC_URL + '/images/partenaire4.jpg'} alt="Partenaire 4"/>

            </PatnerPageStyle>
        </MainLayout>
    )
}
