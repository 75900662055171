import styled from 'styled-components'

export const MenuStyle = styled.div`
  font-family: 'parchment', cursive;
  
  @media screen and (min-width: 1200px) {
    position: relative;
    padding: 0 115px;
    width: 100%;
  }
`

export const MobileBurgerMenu = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  color: #5b7cbd;
  @media screen and (min-width: 1200px) {
    display: none;
  }
`

export const MenuContent = styled.nav`
  visibility: visible;
  position: fixed;
  top: 0;
  width: 225px;
  height: 100vh;
  background: white;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  display: flex;
  flex-direction: column;
  padding: 105px 10px 0;
  left: -255px;
  transition: left .5s cubic-bezier(.03, .86, .53, 1);
  transition-timing-function: cubic-bezier(.03, .86, .53, 1);

  &.open {
    left: 0;
  }

  & > a {
    color: inherit;
    text-decoration: none;
    padding: 15px 0 15px 5px;
    border-bottom: 2px solid rgba(223, 141, 221);
    font-size: 1.8rem;
    letter-spacing: 0.06rem;
    flex-shrink: 1;
    &.active {
      color: #5b7cbd;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  @media screen and (min-width: 1200px) {
    position: initial;
    flex-direction: row;
    box-shadow: none;
    border: none;
    width: 100%;
    height: auto;
    padding: 10px 0 0 0;
    justify-content: space-evenly;
    align-content: center;
    background: rgb(170 180 216);
    border-radius: 40px 40px 0 0;

    &::before {
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      left: 66px;
      top: 20px;
      box-shadow: 15px 18px #aab4d8;
      background: transparent;
      z-index: -1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      right: 66px;
      top: 20px;
      box-shadow: -15px 18px #aab4d8;
      background: transparent;
      z-index: -1;
    }

    & > a {
      border-bottom: none;
      text-align: center;
      padding: 15px 0;
      color: white;
      font-weight: 500;
      overflow: hidden;
      background: linear-gradient(to right, rgb(196, 111, 189), rgb(196, 111, 189) 50%, white 50%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 200% 100%;
      background-position: 100%;
      transition: background-position 275ms ease;
      
      &:hover {
        background-position: 0 100%;
      }
      
      &.active {
        background-position: 0 100%;
        font-weight: bold;
      }
    }
  }
`

export const MenuBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.3);
  visibility: hidden;
  transition: all 1s cubic-bezier(0.000, 1.065, 0.135, 1.010); /* custom */
  transition-timing-function: cubic-bezier(0.000, 1.065, 0.135, 1.010); /* custom */
  opacity: 0;
  
  &.open {
    visibility: visible;
    opacity: 1;
  }
  
`

export const CloseMenuButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  color: #5b7cbd;

  @media screen and (min-width: 1200px) {
    display: none;
  }
  
`
