import React from 'react'
import MainLayout from "../../components/layout/main.layout"
import {AboutContent, AboutList, AboutPageStyle, Paragraphe1, Paragraphe2} from "./About.page.style"

export const AboutPage = () => {
    return (
        <MainLayout>
            <AboutPageStyle>
                <img src={process.env.PUBLIC_URL + '/images/qsuisj1.jpg'} />
                <AboutContent>
                    <AboutList>
                        <li>Cavalière depuis 22 ans.</li>
                        <li>Diplômée Equithérapeute auprès de la Société Française d'Equithérapie (SFE). Signataire de la Charte d'Ethique et de Déontologie des équithérapeutes.</li>
                    </AboutList>
                    <Paragraphe1>Exéprience auprès de personnes dépressives, de détenus, de patients présentant des troubles alimentaires (anorexie), d'enfants handicapés moteurs ou atteints de troubles du développement.</Paragraphe1>
                    <AboutList>
                        <li>Diplômée Educatrice Spécialisée, je travaille actuellement auprès d'enfants en situation de handicap moteur avec ou sans troubles associés.</li>
                    </AboutList>
                    <Paragraphe2>Auparavant, j'ai travaillé auprès d'adultes en grandes difficultés et je les accompagnais dans leur projet de réinsertion socio-professionnelle. J'ai aussi exercé ma profession dans le champ de la protection de l'enfance au sein de villages d'enfants.</Paragraphe2>

                </AboutContent>
            </AboutPageStyle>
        </MainLayout>
    )
}
