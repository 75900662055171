import styled from 'styled-components'

export const LogoImage = styled.img``

export const HeaderComponentStyle = styled.header`
  text-align: center;
  padding: 20px 25px 0 25px;
  background: linear-gradient(#e9e9e9,#d3d1e6);
  width: 100%;
  
  & > img {
    width: 100%;
    max-width: 556px;
  }
`
