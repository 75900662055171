import React from 'react'
import MainLayout from "../../components/layout/main.layout"
import {AlignImages, AlignImages2, FirstImageHome, HomeLists, HomePageStyle, TitleHome} from "./Home.page.style"

export const HomePage = () => {
    return (
        <MainLayout>
            <HomePageStyle>
                <FirstImageHome src={process.env.PUBLIC_URL + '/images/entete-page-cheval.jpg'}/>
                <TitleHome>Qu'est-ce que l'équithérapie?</TitleHome>
                <div>
                    C'est une prise en charge psychologique combinée à une approche sensorielle et motrice. Elle est
                    dispensée par un thérapeute qualifié. Ce n'est ni un sport ni un loisir adapté, mais un soin basé
                    sur un projet thérapeutique individualisé, dont nous aurons fixé les objectifs ensemble.
                </div>
                <AlignImages>
                    <img src={process.env.PUBLIC_URL + '/images/equi-1.jpg'} alt="Equithérapie 1"/>
                    <img src={process.env.PUBLIC_URL + '/images/equi-2.jpg'} alt="Equithérapie 2"/>
                </AlignImages>
                <TitleHome>A qui s'adresse l'équithérapie?</TitleHome>
                <div>
                    Cette forme de thérapie s'adresse aux enfants, adolescents ou adultes aux problématiques diverses:
                    <HomeLists>
                        <li>situation de handicap moteur, mental ou sensoriel</li>
                        <li>difficultés sociales, familiales, tout type de difficulté personnelle passagère ou plus
                            durable (dépression, séparation, deuil, maladie, manque de confiance en soi ou d'estime de
                            soi)
                        </li>
                        <li>troubles du langage, de la communication</li>
                        <li>troubles de l'apprentissage (échec scolaire, dyslexie...)</li>
                        <li>troubles du comportement, troubles envahissants du développement (TED), autisme,
                            hyperactivité
                        </li>
                        <li>difficultés émotionnelles (stress, phobies...)</li>
                    </HomeLists>
                </div>
                <TitleHome>Quels sont les objectifs?</TitleHome>
                <div>
                    <HomeLists>
                        <li>Les chevaux sont authentiques, ne jugent pas et sont révélateurs d'émotions chez le patient.
                            Ils favorisent les prises de conscience, la restitution de l'estime de soi et une meilleure
                            compréhension de l'histoire et du fonctionnement de chacun. Ils permettent alors
                            l'acquisition de nouvelles compétences motrices, cognitives et sociales.
                        </li>
                        <li>L'équithérapeute propose des activités autour du cheval: le patient ne sera donc pas
                            toujours sur le cheval, mais avec lui. Cela comprend la rencontre et la découverte de
                            l'animal, les soins, le travail à pied (cheval en longe ou en liberté), un nouveau mode de
                            communication, de la relaxation à cheval et différents jeux thérapeutiques.
                        </li>
                    </HomeLists>
                </div>
                <AlignImages2>
                    <img src={process.env.PUBLIC_URL + '/images/equi-4.jpg'} alt="Equithérapie 3"/>
                    <img src={process.env.PUBLIC_URL + '/images/equi-3.jpg'} alt="Equithérapie 4"/>
                    <img src={process.env.PUBLIC_URL + '/images/equi-5.jpg'} alt="Equithérapie 5"/>
                </AlignImages2>
            </HomePageStyle>
        </MainLayout>
    )
}
