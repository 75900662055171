import styled from 'styled-components'


export const MaintenanceStyle = styled.div`
  text-align: center;
  font-size: 2.5rem;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: bold;
  color: #5b7cbd;
`

export const FindUs = styled.div`
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > a {
    margin-left: 10px;
    color: #1b74e4;

    &:visited {
      color: #1b74e4;
    }
  }
`
