import React from 'react'
import {HeaderComponentStyle, LogoImage} from "./header.component.style";

const HeaderComponent = () => {
    return (
        <HeaderComponentStyle>
            <LogoImage src={process.env.PUBLIC_URL + '/images/logo.png'}/>
        </HeaderComponentStyle>
    )
}

export default HeaderComponent
