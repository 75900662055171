import React from 'react'
import MainLayout from "../../components/layout/main.layout"
import {BoldPart, SupportPageStyle} from "./Support.page.style"

export const SupportPage = () => {
    return (
        <MainLayout>
            <SupportPageStyle>
                <img src={process.env.PUBLIC_URL + '/images/prise-en-charge-end.jpg'} alt="Prise en charge"/>
                <p>Les séances peuvent être prescrites ou conseillées par un médecin, une équipe médico-sociale, un membre de la famille, ou le patient lui-même.</p>

                <p>Lors de notre première rencontre, nous fixons vos objectifs en fonction de vos attentes et besoins.</p>

                <p>Avant la première séance, nous signons <BoldPart> un contrat de prise en charge en équithérapie.</BoldPart></p>

                <p>Je propose des séances individuelles d'1heure. Elles sont hebdomadaires et ont lieu à heure fixe. Pour le bon déroulement de la prise en charge, il est préférable que les séances se déroulent sans la présence d'une tierce personne, car cela altererait le comportement du patient. Cependant un temps d'échange avec la famille est prévu après chaque séance.</p>

                <p><BoldPart>Des compte-rendus de prise en charge</BoldPart> pourront vous être fournis sur demande.</p>

                <p>Les tarifs incluent ma prestation, la location des chevaux et poneys, du matériel et d'un espace de travail de qualité.</p>
            </SupportPageStyle>
        </MainLayout>
    )
}
