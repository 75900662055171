import React from 'react'
import MainLayout from "../../components/layout/main.layout"
import {AccessSection, AccessTitle, BoldPartAccess, ContactPageStyle, ContactTitle, Map} from "./Contact.page.style"
import {MailContent} from "../Support/Support.page.style";

export const ContactPage = () => {
    return (
        <MainLayout>
            <ContactPageStyle>
                <ContactTitle>
                    Pour me contacter:
                </ContactTitle>
                <MailContent>
                    <a href="mailto:emilie.giraudeau@jenparleraiamoncheval.fr">emilie.giraudeau@jenparleraiamoncheval.fr</a>
                </MailContent>

                <ContactTitle>
                    Accès:
                </ContactTitle>
                <p>
                    Les séances ont lieu aux Ecuries Fidelis 9 chemin du Vaudoué, 77760 Achères-la-Fôret.
                </p>
                <Map>
                    <iframe
                        title="Google Maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2651.2620760535906!2d2.5566146155582574!3d48.35548754393761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e592db54fe0959%3A0xdb925a8a901136bc!2sEcuries%20Fidelis!5e0!3m2!1sfr!2sfr!4v1643191323609!5m2!1sfr!2sfr"
                        width="100%"
                        height="450"
                        allowFullScreen=""
                        loading="lazy">
                    </iframe>
                </Map>
                <AccessSection>
                    <AccessTitle>Par la route</AccessTitle>
                    <p><BoldPartAccess>Depuis Paris (35 minutes de la Porte d'Orléans):</BoldPartAccess> Prendre l'autoroute du sud (A6) jusqu'à la sortie 14 (Malesherbes), prendre à gauche direction URY puis tout de suite à gauche direction ACHERES LA FORET. Arrivé à Achères, suivre la direction "Le Vaudoué", juste avant de sortir d'Achères prendre à gauche le chemin du Vaudoué (au niveau de l'arrêt de bus) : les écuries sont au bout du chemin !</p>
                    <p><BoldPartAccess>Depuis Fontainebleau (15 minutes du carrefour de l'Obélisque) :</BoldPartAccess> Prendre, au carrefour de l'obélisque, la N152 direction URY. Arrivé à URY, prendre à droite la D63 direction ACHERES-LA-FORET. Arrivé à Achères, suivre la direction "Le Vaudoué", juste avant de sortir d'Achères prendre à gauche le chemin du Vaudoué (au niveau de l'arrêt de bus) : les écuries sont au bout du chemin !</p>
                    <AccessTitle>En Transport (depuis Paris)</AccessTitle>
                    <p>Prendre, à Gare de Lyon, le Transilien SNCF ligne R direction MONTEREAU ou MONTARGIS (tous les horaires sur <a href="https://www.transilien.com" target="_blank">www.transilien.com</a>). Descendre à la gare de FONTAINEBLEAU-AVON.</p>
                    <p>A la gare routière (située derrière la gare SNCF), prendre la ligne 184-001 des CARS BLEUS (<a href="https://www.cars-bleus.fr/lignes-regulieres/la-chapelle-fontainebleau-avon-noisy-sur-ecole-ury/" target="_blank">horaires</a>) direction LA CHAPELLE LA REINE. Descendre à LA CHAPELLE LA REINE. Prendre la ligne 184-003 des CARS BLEUS (<a target="_blank" href="https://www.cars-bleus.fr/lignes-regulieres/noisy-sur-ecole-la-chapelle-la-reine-boisminard">horaires</a>) direction ACHERES. Descendre à l'arrêt EGLISE. Descendre le chemin du Vaudoué à pied jusqu'à numéro 9 : vous êtes arrivés !</p>
                </AccessSection>
            </ContactPageStyle>
        </MainLayout>
    )
}
