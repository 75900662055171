import React from 'react'
import HeaderComponent from "../Header/header.component";
import {MenuComponent} from "../Menu/Menu.component";
import {MainContainer, MainContent} from "./main.layout.style";
import {FooterComponent} from "../Footer/Footer.component";

const MainLayout = ({children}) => {
    return (
        <>
            <HeaderComponent/>
            <MainContainer>
                <MenuComponent />
                <MainContent>
                    { children }
                </MainContent>
                <FooterComponent />
            </MainContainer>
        </>
    )
}

export default MainLayout
