import './App.css'
import {Routes, Route} from "react-router-dom"
import {MaintenancePage} from "./pages/Maintenance/Maintenance.page"
import { library } from '@fortawesome/fontawesome-svg-core'
import {faFacebook} from "@fortawesome/free-brands-svg-icons/faFacebook"
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars"
import {HomePage} from "./pages/Home/Home.page"
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes"
import {AboutPage} from "./pages/About/About.page";
import {SupportPage} from "./pages/Support/Support.page";
import {PartnerPage} from "./pages/Partner/Partner.page";
import {ContactPage} from "./pages/Contact/Contact.page";

library.add(faFacebook, faBars, faTimes)

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/qui-suis-je" element={<AboutPage />} />
        <Route path="/prise-en-charge" element={<SupportPage />} />
        <Route path="/partenaire" element={<PartnerPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </div>
  );
}

export default App;
