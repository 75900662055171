import styled from "styled-components"

export const ContactPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContactTitle = styled.h1`
  font-family: 'parchment', cursive;
  font-size: 1.5rem;
  text-decoration: underline;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 10px 0;
`

export const AccessSection = styled.section`
  p {
    margin-bottom: 35px;
    line-height: 22px;
  }
`

export const AccessTitle = styled.h1`
  font-family: 'parchment', cursive;
  font-size: 1.7rem;
  font-weight: bold;
  color: rgb(0, 0, 128);
  margin: 15px 0;
  letter-spacing: 0.05em;
`

export const Map = styled.div`
  margin: 15px 0;
  width: 100%;
`

export const BoldPartAccess = styled.span`
  font-weight: 600;
`
